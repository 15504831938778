import React from 'react';
import './App.css';
import axios from 'axios';
import TradingViewWidget from './Parser'
import Testik from './Testik';
import logo from './p2pLogo.png'

function App() {

  const [binanceXrpRate, setXrpBinanceRate] = React.useState(0);
  const [bithumbXrpRate, setBithumbXrpRate] = React.useState(0);
  const [xrpSummuryRate, setXrpSummuryRate] = React.useState(0);

  const [bithumbUsdtRate, setBithumbUsdtRate] = React.useState(0);

  const [binanceEthRate, setBinanceEthRate] = React.useState(0);
  const [bithumbEthRate, setBithumbEthRate] = React.useState(0);
  const [ethSummuryRate, setEthSummuryRate] = React.useState(0);

  const [binanceBtcRate, setBinanceBtcRate] = React.useState(0);
  const [bithumbBtcRate, setBithumbBtcRate] = React.useState(0);
  const [btcSummuryRate, setBtcSummuryRate] = React.useState(0);


  function Interval() {
    setInterval(() => {
      axios.get('https://api.binance.com/api/v3/ticker/24hr?symbol=XRPUSDT')
        .then((res) => {
          setXrpBinanceRate(Number(res.data.lastPrice));
        })
      axios.get('https://api.bithumb.com/public/ticker/XRP_KRW')
        .then((res) => {
          setBithumbXrpRate(Number(res.data.data.closing_price));
        })
      axios.get('https://api.bithumb.com/public/ticker/USDT_KRW')
        .then((res) => {
          setBithumbUsdtRate(Number(res.data.data.closing_price));
        })
      axios.get('https://api.binance.com/api/v3/ticker/24hr?symbol=ETHUSDT')
        .then((res) => {
          setBinanceEthRate(Number(res.data.lastPrice));
        })
      axios.get('https://api.bithumb.com/public/ticker/ETH_KRW')
        .then((res) => {
          setBithumbEthRate(Number(res.data.data.closing_price));
        })


      axios.get('https://api.binance.com/api/v3/ticker/24hr?symbol=BTCUSDT')
        .then((res) => {
          setBinanceBtcRate(Number(res.data.lastPrice));
        })
      axios.get('https://api.bithumb.com/public/ticker/BTC_KRW')
        .then((res) => {
          setBithumbBtcRate(Number(res.data.data.closing_price));
        })

    }, 2000)

  }
  /*   console.log(typeof (binanceXrpRate), binanceXrpRate, 'XRP binance')
    console.log(typeof (bithumbXrpRate), bithumbXrpRate, 'XRP bithumb')
    console.log(typeof (XrpSummuryRate), XrpSummuryRate, 'XRP Exchange Rate') */

  console.log('XRP binance', binanceXrpRate)
  console.log('USDT bithumb', bithumbUsdtRate)

  console.log('XRP bithumb', bithumbXrpRate)
  console.log('XRP Exchange Rate', xrpSummuryRate)

  console.log('ETH binance', binanceEthRate)
  console.log('ETH bithumb', bithumbEthRate)
  console.log('ETH Exchange Rate', ethSummuryRate)

  console.log('BTC binance', binanceBtcRate)
  console.log('BTC bithumb', bithumbBtcRate)
  console.log('BTC Exchange Rate', btcSummuryRate)

  React.useEffect(() => {
    Interval();
  }, [])

  React.useEffect(() => {
    setXrpSummuryRate(Number(bithumbXrpRate / binanceXrpRate));
    setEthSummuryRate(Number(bithumbEthRate / binanceEthRate));
    setBtcSummuryRate(Number(bithumbBtcRate / binanceBtcRate));
  }, [bithumbXrpRate, binanceXrpRate, bithumbEthRate, binanceEthRate, bithumbBtcRate, binanceBtcRate])

  React.useEffect(() => {
    document.title = bithumbUsdtRate.toFixed(2);
  }, [bithumbUsdtRate])

  return (

    <div >
      <div className='header'>
      </div>
      <div className='app info'>

        <div className='info-block border'>
          <p className='info-name'>XRP</p>
          <div className='digits'>{xrpSummuryRate.toFixed(2)}</div>
        </div>
        <div className='info-block border'>
          <p className='info-name'>USDT</p>
          <div className='digits'>{bithumbUsdtRate.toFixed(2)}</div>
        </div>
        <div className='info-block'>
          <p className='info-name'>ETH</p>
          <div className='digits'>{ethSummuryRate.toFixed(2)}</div>
        </div>
        <div className='info-block'>
          <p className='info-name'>BTC</p>
          <div className='digits'>{btcSummuryRate.toFixed(2)}</div>
        </div>
        <div className='exchange-rate'>
          <iframe src='https://www.xe.com/currencyconverter/convert/?Amount=1&From=USD&To=KRW' className='exchange-info' scrolling='no'></iframe>
        </div>
      </div>



      {/* <TradingViewWidget /> */}
      {/* <Testik /> */}
    </div>

  );

}

export default App;
